import React, { memo } from 'react';
import { InputField } from '$shared/components';
import { useCombobox } from 'downshift';
import { Option } from '.';
import ChevronDown from '$icons/chevron-down.svg';
import { StyledIcon, StyledSelector, StyledOptions } from './styled';

type InputProps = React.InputHTMLAttributes<HTMLInputElement>;

export type Option = {
    value: string;
    title?: string;
    disabled?: boolean;
};

export type SelectorProps = InputProps & {
    /**
     * Adds a label to the input field. This is required for accessibilty.
     */
    label?: string;

    /**
     * Add an additional help text below the input field.
     */
    helpText?: string;

    /**
     * Add an additional help text below the input field.
     */
    invalidMessage?: string;

    /**
     * Set styling to indicate input is invalid.
     * Also shows the `invalidMessage` if provided
     */
    isInvalid?: boolean;

    /**
     * The selected Option
     */
    value?: string;

    /**
     *
     */
    options: Option[];
    onChangeHandler?: (option: Option | undefined | null) => void;
};

export const Selector = React.forwardRef<HTMLInputElement, SelectorProps>(
    ({ options, onChangeHandler, ...rest }, ref) => {
        const {
            isOpen,
            getInputProps,
            getComboboxProps,
            getMenuProps,
            highlightedIndex,
            getItemProps,
            closeMenu,
            openMenu,
        } = useCombobox<Option>({
            items: options,
            itemToString: (item) => item?.value || '',
            onSelectedItemChange: ({ selectedItem }) => {
                onChangeHandler?.(selectedItem);
                closeMenu();
            },
        });

        const containerProps = getComboboxProps();

        const inputProps = getInputProps({
            ...rest,
            ref,
            readOnly: true,
            type: 'text',
            onClick: () => {
                isOpen ? closeMenu() : openMenu();
            },
            onKeyDown: (event) => {
                if (event.code !== 'Enter' && event.code !== 'Space') {
                    return;
                }
                event.preventDefault();

                if (!isOpen) {
                    openMenu();
                    return;
                }

                onChangeHandler?.(options[highlightedIndex]);
                closeMenu();
            },
        });

        const openIcon = (
            <StyledIcon isOpen={isOpen}>
                <ChevronDown aria-hidden="true" />
            </StyledIcon>
        );

        return (
            <StyledSelector {...containerProps}>
                <InputField {...inputProps} isActive={isOpen} trailingIcon={openIcon}>
                    <StyledOptions {...getMenuProps()}>
                        {isOpen &&
                            options.map((item, index) => (
                                <Option
                                    selected={highlightedIndex === index}
                                    key={item.value}
                                    {...item}
                                    {...getItemProps({
                                        item,
                                        disabled: item.disabled,
                                        index,
                                    })}
                                >
                                    {item.title || item.value}
                                </Option>
                            ))}
                    </StyledOptions>
                </InputField>
            </StyledSelector>
        );
    }
);

export default memo(Selector);
