import styled from '@emotion/styled';
import { ifNotProp, ifProp } from 'styled-tools';
import { StyledText } from '~/shared/components/Text';

export const StyledCheckboxWrapper = styled.div<{
    disabled?: boolean;
    hasLabel: boolean;
    round: boolean;
    valid: boolean;
    fillRow: boolean;
}>(
    ({ theme, disabled }) => ({
        padding: `${theme.spaces[2]} ${theme.spaces[3]}`,
        display: 'inline-grid',
        gridTemplateColumns: `20px 1fr`,
        position: 'relative',
        zIndex: 1,
        pointerEvents: 'none',
        opacity: disabled ? 0.5 : 1,

        '>*': {
            pointerEvents: disabled ? 'none' : 'auto',
        },

        ':hover': {
            [`${StyledCheckboxIndicator}:before`]: {
                borderColor: theme.colors.light50,
            },
        },
    }),
    ifProp('hasLabel', ({ theme }) => ({
        ':focus-visible': {
            outline: `2px solid ${theme.colors.blue20}`,
        },
    })),
    ifProp('round', ({ theme }) => ({
        ':hover': {
            [`${StyledCheckboxIndicator}:before`]: {
                borderColor: theme.colors.light30,
            },
        },
    })),
    ifProp('fillRow', {
        width: '100%',
    }),
    ifNotProp('valid', ({ theme }) => ({
        [`${StyledLabel}`]: {
            color: theme.colors.error,
        },
        [`${StyledCheckboxIndicator}`]: {
            borderColor: theme.colors.error,
        },
        [`${StyledCheckboxElement}`]: {
            borderColor: theme.colors.error,
        },
    }))
);

export const StyledCheckboxElement = styled.div<{
    round: boolean;
    hasLabel: boolean;
    alignment?: 'center' | 'start';
}>(
    ({ theme, alignment = 'center' }) => ({
        border: theme.borders.border,
        width: 20,
        height: 20,
        cursor: 'pointer',
        backgroundColor: theme.colors.light,
        zIndex: 1,
        alignSelf: alignment,
        marginTop: alignment === 'start' ? theme.spaces[1] : 0,
        [`input:checked ~ ${StyledCheckboxIndicator}`]: {
            backgroundColor: theme.colors.dark,
            color: theme.colors.light,
        },
        [`input:focus-visible ~ ${StyledCheckboxIndicator}:before`]: {
            borderColor: theme.colors.blue20,
        },
        [`input:active ~ ${StyledCheckboxIndicator}:before`]: {
            borderColor: theme.colors.dark70,
        },
    }),
    ifProp('round', ({ theme }) => ({
        borderRadius: '100%',
        border: 'none',
        [`input:checked ~ ${StyledCheckboxIndicator}`]: {
            background: theme.colors.light,
            '&:after': {
                content: "''",
                display: 'block',
                width: 10,
                height: 10,
                backgroundColor: theme.colors.dark,
                borderRadius: '50%',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                position: 'absolute',
            },
        },
        [`input:active ~ ${StyledCheckboxIndicator}:before`]: {
            borderColor: theme.colors.dark70,
        },
        [`& ${StyledCheckboxIndicator}:before`]: {
            borderRadius: '50%',
        },
    })),
    ifProp('hasLabel', ({ theme }) => ({
        ['& ~ *']: {
            marginLeft: theme.spaces[3],
        },
        [`input:focus-visible ~ ${StyledContainerOutline}`]: {
            borderColor: theme.colors.blue20,
        },
    }))
);

export const StyledCheckbox = styled.input(() => ({
    cursor: 'inherit',
    margin: 0,
    opacity: 0,
    padding: 0,
    position: 'absolute',
    overflow: 'hidden',
    width: 20,
    height: 20,
    zIndex: 3,
}));

export const StyledContainerOutline = styled.div(() => ({
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    border: '2px solid transparent',
    position: 'absolute',
    zIndex: -1,
    pointerEvents: 'none',
}));

export const StyledCheckboxIndicator = styled.div<{ round: boolean }>(
    () => ({
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: 20,
        height: 20,
        backgroundColor: 'transparent',
        borderRadius: 'inherit',
        pointerEvents: 'none',
        zIndex: 1,
        color: 'transparent',
        position: 'relative',
        top: -1,
        left: -1,
        [':before']: {
            display: 'block',
            content: "''",
            width: 28,
            height: 28,
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            background: 'transparent',
            pointerEvents: 'none',
            borderWidth: '2px',
            borderStyle: 'solid',
            borderColor: 'transparent',
            position: 'absolute',
        },
    }),
    ifProp('round', ({ theme }) => ({
        border: `1px solid ${theme.colors.light50}`,
        [':before']: {
            borderWidth: '4px',
            borderRadius: '50%',
        },
    }))
);

export const StyledLabel = styled.label(({ theme }) => ({
    color: theme.colors.dark,
    fontSize: theme.fontSizes.sm,
    display: 'block',
    cursor: 'pointer',
    lineHeight: '20px',
    zIndex: 2,

    [`${StyledText}`]: {
        fontSize: theme.fontSizes.sm,
    },
}));

export const StyledMessages = styled.div(({ theme }) => ({
    marginLeft: theme.spaces[3],
}));

export const StyledFocusOutline = styled.div(({ theme }) => ({
    width: '100%',
    height: '100%',
    borderRadius: 'inherit',
    background: theme.colors.blue10,
}));
