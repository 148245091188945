import React, { forwardRef, useCallback, useState } from 'react';
import { useBringApi } from '~/features/checkout';
import { CountryCode, SupportedCountryCodes } from '../../models/country';
import { InputField, InputFieldProps } from '~/shared/components';
import { useTranslation } from '~/shared/utils';
import { useCountryOptions, useValidationRules } from '~/shared/utils/form';

type Props = {
    /**
     * Callback which will be called with result or and empty result on errors
     */
    onZipValidation: (city: string) => void;
};

export const ZipAutoComplete = forwardRef<HTMLInputElement, InputFieldProps & Props>(
    ({ onZipValidation, ...restProps }, ref) => {
        const { translate } = useTranslation();
        const [postalHelpText, setPostalHelpText] = useState<string | undefined>();
        const { currentDeliveryKey } = useCountryOptions();

        const validationRules = useValidationRules(currentDeliveryKey);

        const { add: addZip } = useBringApi();

        const postalCodeAutocomplete = useCallback(
            async (postalCode: string) => {
                // Eject if unsupported country
                if (!SupportedCountryCodes[currentDeliveryKey as CountryCode]) {
                    return;
                }

                const validator = new RegExp(validationRules.zipcodeRegex || '');
                try {
                    // Validate postal code
                    if (!validator.test(postalCode)) {
                        throw new Error();
                    }

                    const { city, valid } = await addZip({ postalCode });

                    if (city) {
                        onZipValidation(city);
                        setPostalHelpText('');
                    }

                    // Unmatched search, enable helptext
                    if (!city && valid) {
                        setPostalHelpText(translate('form.helpText.postalCode'));
                    }
                } catch {
                    onZipValidation('');
                }
            },
            [addZip, onZipValidation, translate, validationRules.zipcodeRegex, currentDeliveryKey]
        );

        return (
            <InputField
                ref={ref}
                type="tel"
                autoComplete="postal-code"
                label={translate('form.label.postalCode')}
                placeholder={translate('form.placeholder.postalCode')}
                disabled={!currentDeliveryKey}
                onInput={(e) => postalCodeAutocomplete((e.target as HTMLInputElement).value)}
                {...restProps}
                invalidMessage={restProps.invalidMessage || postalHelpText}
            />
        );
    }
);
