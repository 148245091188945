import { useMutation } from 'react-query';
import { getCityFromZip } from '../service/bring';
import { CountryCode, SupportedCountryCodes } from '../models/country';
import { BringPostalCodeArgs, BringResponse } from '~/lib/data-contract';
import { useEffect } from 'react';
import { useCountryOptions } from '~/shared/utils/form';
import create from 'zustand';

type BringState = {
    hasPostalLookupSupport: boolean;
    setHasPostalLookupSupport: (hasSupport: boolean) => void;
};

const useBringState = create<BringState>((set) => ({
    hasPostalLookupSupport: false,
    setHasPostalLookupSupport: (hasPostalLookupSupport) => {
        set(() => ({
            hasPostalLookupSupport,
        }));
    },
}));

export const useBringApi = () => {
    const { currentDeliveryKey } = useCountryOptions();
    const { hasPostalLookupSupport, setHasPostalLookupSupport } = useBringState();

    const countryCode = currentDeliveryKey as CountryCode;

    useEffect(() => {
        setHasPostalLookupSupport(Boolean(countryCode && SupportedCountryCodes[countryCode]));
    }, [setHasPostalLookupSupport, countryCode]);

    const { isLoading, isSuccess, mutateAsync: add } = useMutation<
        BringResponse,
        Error,
        BringPostalCodeArgs
    >(({ postalCode }) => getCityFromZip({ postalCode, countryCode }, '/api/bring'), {
        onError: () => {
            setHasPostalLookupSupport(false);
        },
        onSuccess: ({ valid }) => {
            const isStillValid = hasPostalLookupSupport && valid;
            setHasPostalLookupSupport(isStillValid);
        },
    });

    return {
        isLoading,
        add,
        isSuccess,
        hasPostalLookupSupport,
    };
};
