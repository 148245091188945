import { InvalidMessage } from '../InvalidMessage';
import { HelpText } from '../HelpText';
import React, { useImperativeHandle, useRef, useState } from 'react';
import { useInputField } from '../../hooks/useInputField';
import {
    StyledCheckboxWrapper,
    StyledCheckboxElement,
    StyledCheckbox,
    StyledCheckboxIndicator,
    StyledLabel,
    StyledMessages,
    StyledContainerOutline,
} from './styled';
import Check from '$icons/check.svg';

type InputProps = React.InputHTMLAttributes<HTMLInputElement>;

export type CheckboxProps = Omit<InputProps, 'placeholder' | 'value'> & {
    /**
     * Adds a label to the input field. This is required for accessibilty.
     */
    label?: string | React.ReactNode;

    /**
     * Add an additional help text below the input field.
     */
    helpText?: string;

    /**
     * Add an additional help text below the input field.
     */
    invalidMessage?: string;

    /**
     * Set styling to indicate input is invalid.
     * Also shows the `invalidMessage` if provided
     */
    isInvalid?: boolean;

    /**
     * Toggle between checkbox or radio
     */
    type?: 'checkbox' | 'radio';

    /**
     * Make the checkbox fill the row. Useful for capturing events on the whole row on e.g. mobile
     */
    fillRow?: boolean;

    /**
     * Alignment of checkbox relative to label
     */
    alignment?: 'center' | 'start';
};

export const Checkbox = React.forwardRef<HTMLInputElement, CheckboxProps>(
    (
        {
            label,
            helpText,
            invalidMessage,
            isInvalid,
            id,
            onChange,
            type = 'checkbox',
            fillRow = false,
            alignment,
            ...rest
        },
        ref
    ) => {
        const [isValidityValid, setIsValidityValid] = useState(true);
        const isValid = !isInvalid && isValidityValid;
        const inputRef = useRef<HTMLInputElement>(null);

        const {
            fieldId,
            helpTextId,
            invalidMessageId,
            describedById,
            showHelpText,
            showInvalidMessage,
        } = useInputField({
            id,
            helpText,
            invalidMessage,
            isInvalid,
        });

        const onChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
            setIsValidityValid(inputRef.current?.validity.valid ? true : false);
            onChange && onChange(event);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
        useImperativeHandle(ref, () => inputRef.current as HTMLInputElement, [ref]);

        return (
            <div key={fieldId}>
                <StyledCheckboxWrapper
                    round={type === 'radio'}
                    disabled={rest.disabled}
                    hasLabel={label ? true : false}
                    valid={!isInvalid}
                    fillRow={fillRow}
                >
                    <StyledCheckboxElement
                        alignment={alignment}
                        round={type === 'radio'}
                        hasLabel={label ? true : false}
                    >
                        <StyledCheckbox
                            type={type}
                            id={fieldId}
                            ref={inputRef}
                            aria-describedby={describedById}
                            onChange={onChangeHandler}
                            {...rest}
                        />
                        <StyledCheckboxIndicator round={type === 'radio'}>
                            <Check aria-hidden="true" />
                        </StyledCheckboxIndicator>
                        <StyledContainerOutline />
                    </StyledCheckboxElement>
                    {label && typeof label === 'string' ? (
                        <StyledLabel htmlFor={fieldId} title={label} children={label} />
                    ) : null}
                    {label && typeof label !== 'string' ? (
                        <StyledLabel htmlFor={fieldId} children={label} />
                    ) : null}
                </StyledCheckboxWrapper>
                <StyledMessages>
                    {!isValid && showInvalidMessage ? (
                        <InvalidMessage id={invalidMessageId} children={invalidMessage} />
                    ) : null}
                    {showHelpText ? <HelpText id={helpTextId} children={helpText} /> : null}
                </StyledMessages>
            </div>
        );
    }
);
