export enum SupportedCountryCodes {
    NO = 'NO',
    DK = 'DK',
    SE = 'SE',
    FI = 'FI',
    NL = 'NL',
    DE = 'DE',
    US = 'US',
    BE = 'BE',
    FO = 'FO',
    GL = 'GL',
    IS = 'IS',
    SJ = 'SJ',
}

export type CountryCode = keyof typeof SupportedCountryCodes;
